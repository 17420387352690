
import { Component, Mixins } from 'vue-property-decorator';
import { TestimonialFilter } from 'client-website-ts-library/filters';
import { View } from 'client-website-ts-library/plugins';
import { WebsiteLevel } from 'client-website-ts-library/types';

import TestimonialList from '../components/Testimonials/TestimonialList.vue';

@Component({
  components: {
    TestimonialList,
  },
})
export default class Testimonials extends Mixins(View) {
    private filter: TestimonialFilter = new TestimonialFilter({
      SearchLevel: WebsiteLevel.Office,
      SearchGuid: '97eea4c0-6832-44d3-9b4a-8639d06b4804',
    });
}
